export default defineNuxtRouteMiddleware((to) => {
  const { isMobile } = useDevice();

  if (!isMobile) {
    return navigateTo({
      name: 'account',
      query: { active: to.name?.toString().replace('account-', '') }
    });
  }

  const store = useAuthStore();

  if (!store.isAuthenticated) {
    return navigateTo({
      name: 'login',
      query: { redirect: to.path }
    });
  }
});
